const button = {
    width: '150px',
    height: '44px',
    borderRadius: '22.5px',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 20px'
}

const styles = theme =>({
    
    button1: {
        ...button,
        backgroundColor: '#FFFFFF',
        border: '1px solid #6551FF',
        color: '#6551FF',
        '&:disabled':{
            opacity: '0.5',
            cursor: 'auto'
        }
    },
    button2: {
        ...button,
        backgroundColor: '#F63F40',
        border: '1px solid #F63F40',
        color: '#FFFFFF',
        '&:disabled':{
            opacity: '0.5',
            cursor: 'auto'
        }
    },
    

})
export default styles