import { ideaStatusDiscardedMapper, ideaStatusMapper } from 'pagesNewUi/new_idea_detail/Constants'
import * as kpiTypes from 'common_constants/Kpis'
import i18n from 'support/i18n'
import { transformToEmbedUrl } from 'support/utils/file'
import { formatLocaleDistanceToNow } from 'support/date_fns_locale'
// import { id } from 'date-fns/locale'

export const transformServiceLineStatusResponse = jsonResponse => {
  return {
    serviceLineStatus: jsonResponse.isServiceLineActivated,
  }
}

export const canSendIdeaTeamInvitations = status => {
  return (
    ideaStatusMapper.SUPPORTED === ideaStatusMapper[status] ||
    ideaStatusMapper.DRAFT === ideaStatusMapper[status] ||
    ideaStatusMapper.PENDING_SUPPORT === ideaStatusMapper[status]
  )
}

export const canSendPilotProjectTeamInvitations = status => {
  return (
    ideaStatusMapper.APPROVED_BY_IT === ideaStatusMapper[`${status}`] ||
    ideaStatusMapper.IMPLEMENTED === ideaStatusMapper[status] ||
    ideaStatusMapper.PILOT_ACTIVE === ideaStatusMapper[status]
  )
}

export const getIdeaStatusTitleAndMessage = idea => {
  let statusMessageTitle = ''
  let statusMessageText = ''
  switch (true) {
    case ideaStatusMapper.APPROVED_BY_IT === ideaStatusMapper[idea.status]:
      statusMessageTitle = i18n.t('EXPLORE_IDEA.APPROVED_BY_IT')
      statusMessageText = idea.approvedText
      break
    case ideaStatusMapper.DISCARDED === ideaStatusMapper[idea.status] &&
      idea.discardedReason === ideaStatusDiscardedMapper.DISCARDED_BY_IT:
      statusMessageTitle = i18n.t('EXPLORE_IDEA.DISCARDED_BY_IT')
      statusMessageText = idea.discardedText
      break
    case ideaStatusMapper.DISCARDED === ideaStatusMapper[idea.status] &&
      idea.discardedReason === ideaStatusDiscardedMapper.INNOVATION_COUNTDOWN_EXPIRED:
      statusMessageText = i18n.t('DISCARDED_IT_EX')
      break
    case ideaStatusMapper.DISCARDED === ideaStatusMapper[idea.status] &&
      idea.discardedReason === ideaStatusDiscardedMapper.INVESTMENT_COUNTDOWN_EXPIRED:
      statusMessageText = i18n.t('DISCARDED_EX_REASON')
      break
    case ideaStatusMapper.PENDING_VALIDATION === ideaStatusMapper[idea.status]:
      statusMessageTitle = i18n.t('EXPLORE_IDEA.PENDING_VALIDATION')
      statusMessageText = idea.feedbackComment
      break
    default:
      break
  }
  return {
    statusMessageTitle,
    statusMessageText,
  }
}

export const getIdeaOrganizationName = organizations => {
  if (organizations && organizations.length) {
    const organization = organizations[0].organization.folder
      ? `${organizations[0].organization.folder} - ${organizations[0].organization.name}`
      : `${organizations[0].organization.name}`
    return {
      name: organization,
      id: organizations[0].organization.id,
    }
  }
  return {}
}

export const getIdeaTeam = team => {
  return team.map(teamMember => ({
    fullName: teamMember.fullname || '',
    photoSrc: teamMember.photo || '',
    amountInvested: teamMember.amountInvested || 0,
    email: teamMember.email || '',
    isAdmin: teamMember.admin,
  }))
}

export const getIdeaGoals = goals => {
  let formattedGoals = {}
  if (goals && goals.length) {
    formattedGoals = [...goals]
    formattedGoals = formattedGoals
      .sort((firstGoal, secondGoal) => {
        return firstGoal.goal.order - secondGoal.goal.order
      })
      .reduce((acc, goal) => {
        acc[`${goal.goal.id}`] = {
          name: goal.goal.name,
          description: goal.goal.desc,
          percentage: goal.fit,
        }
        return acc
      }, {})
  }
  return formattedGoals
}

export const getIdeaChallengeDetails = idea => {
  const challengeDetail = {
    name: '',
    imgSrc: '',
    id: '',
  }
  if (idea.challenge) {
    challengeDetail.name = idea.challenge.name
    challengeDetail.imgSrc = idea.challenge.image.url
    challengeDetail.id = idea.challenge.id
    challengeDetail.images = [idea.challenge.images[0]]
    challengeDetail.desc = idea.challenge.descWithoutTags
    challengeDetail.proposerName = idea.challenge.proposerName
    challengeDetail.ends = idea.challenge.ends
    challengeDetail.starts = idea.challenge.starts
    challengeDetail.rewardText = idea.challenge.rewardDesc
    challengeDetail.numIdeas = idea.challenge.numIdeas
    challengeDetail.chl = idea.challenge
    
  }
  return challengeDetail
}

export const getIdeaSubmitterPromoter = idea => ({
  submitter: {
    fullName: idea.ownerName,
    photo: idea.ownerPhoto,
    email: idea.owner,
  },
  promoter: {
    fullName: idea.promotterName || '',
    photo: idea.promotterPhoto || '',
    email: (idea.promotterKey && idea.promotterKey.raw && idea.promotterKey.raw.name) || '',
  },
})

export const transformImageServiceResponseWithState = jsonResponse => ({
  src: jsonResponse.url,
  key: jsonResponse.key,
  name: jsonResponse.name,
})

export const transformIdeaTitleResponseWithState = jsonResponse => {
  return jsonResponse.available
}

export const transformUploadFileResponse = jsonResponse => {
  return jsonResponse.uploadUrl || jsonResponse.url
}

export function transformDocumentUploadResponse(documentFile) {
  return {
    name: `${documentFile.name}`,
    size: `${(documentFile.size || 0) / 1024}`,
    src: `${documentFile.url}`,
    key: `${documentFile.deleteUrl || documentFile.key}`,
    timeAgo: formatLocaleDistanceToNow(
      new Date(
        documentFile.created ? documentFile.created.timestamp || documentFile.created : new Date(),
      ),
    ),
    deleteUrl: documentFile.deleteUrl,
  }
}

export function transformDeleteDocument(documentId, documents) {
  return documents.filter(document => document.key !== documentId)
}

export const transformKpisResponse = jsonResponse => {
  const { idea } = jsonResponse

  return {
    kpis: idea.kpis.map(kpi => {
      return {
        ...kpi,
        type: kpi.type === 'CUANTITATIVE' ? kpiTypes.QUANTITATIVE : kpiTypes.QUALITATIVE,
      }
    }),
  }
}

export const transformGetIdeaResponse = jsonResponse => {
  const {
    idea,
    team,
    goals,
    organizations,
    types,
    minimumInvest,
    maxInvest,
    innovationTeam = [],
    documents = [],
  } = jsonResponse
// console.log("responsve=>>",jsonResponse)
  const challengeDetail = getIdeaChallengeDetails(idea)
  const formattedGoals = getIdeaGoals(goals)
  const organization = getIdeaOrganizationName(organizations)
  const { statusMessageText, statusMessageTitle } = getIdeaStatusTitleAndMessage(idea)
  const { submitter, promoter } = getIdeaSubmitterPromoter(idea)
  return {
    title: idea.title,
    images: idea.images.map(image => ({
      key: image.key,
      name: image.name,
      src: image.url,
    })),
    description: idea.text,
    videos: {
      externalVideo: (idea.externalVideo && transformToEmbedUrl(idea.externalVideo.url)) || '',
      fileVideo: (idea.video && idea.video.playerUrl) || '',
      isVideoReady: idea.video && idea.video.state === 'ready',
      fileVideoId: idea.video && idea.video.id,
    },
    isGlobal: idea.isGlobal,
    origin: idea.origin,
    challenge: challengeDetail,
    organization,
    type: types && types.length ? { name: types[0].typeDto.name, id: types[0].typeDto.id } : {},
    goals: formattedGoals,
    pilotEstimatedQuantity: `${idea.logistic.pilotMoney || ''}`,
    pilotEstimatedHours: `${idea.logistic.pilotHours || ''}`,
    pilotEstimatedTime: `${idea.logistic.pilotTime || ''}`,
    pilotEstimatedTimeType: `${idea.logistic.pilotTimeUnit || ''}`,
    implementEstimatedQuantity: `${idea.logistic.implementMoney || ''}`,
    implementEstimatedHours: `${idea.logistic.implementHours || ''}`,
    implementEstimatedTime: `${idea.logistic.implementTime || ''}`,
    implementEstimatedTimeType: `${idea.logistic.implementTimeUnit || ''}`,
    economicBenefits: `${idea.logistic.revenueAmount || ''}`,
    otherBenefits: `${idea.logistic.other || ''}`,
    endDate: idea.countdownEnds ? new Date(idea.countdownEnds) : undefined,
    createdDate: idea.created ? new Date(idea.created) : undefined,
    documents: documents.map(transformDocumentUploadResponse),
    isPendingValidation: ideaStatusMapper.PENDING_VALIDATION === ideaStatusMapper[`${idea.status}`],
    status: idea.status,
    statusMessageText,
    statusMessageTitle,
    submitter,
    promoter,
    owner: idea.owner,
    team: getIdeaTeam(team),
    pilotTeam: getIdeaTeam(innovationTeam),
    maxInvestment: maxInvest || 0,
    minInvestment: minimumInvest || 1,
    totalInvestors: `${idea.investors || '0'}`,
    visits: `${idea.visits || '0'}`,
    singleId: idea.singleId || '',
    pledged: `${idea.pledged || '0'}`,
    goal: `${idea.goal || '0'}`,
    canSendIdeaTeamInvitations: canSendIdeaTeamInvitations(idea.status),
    canSendPilotProjectTeamInvitations: canSendPilotProjectTeamInvitations(idea.status),
    isDraft: ideaStatusMapper.DRAFT === ideaStatusMapper[`${idea.status}`],
    isPrivate: idea.isPrivate,
    isAnonymous: idea.anonymous,
    kpis: idea.kpis.map(kpi => {
      return {
        ...kpi,
        type: kpi.type === 'CUANTITATIVE' ? kpiTypes.QUANTITATIVE : kpiTypes.QUALITATIVE,
      }
    }),
    feedbackComment: `${idea.feedbackComment}`,
    feedbackFromNova: idea.feedbackFromNova,
    boardDecision: idea.boardDecision,
    pitchDecision: idea.pitchDecision,
  }
}

export function mapContribution(contribution) {
  return {
    time: `${contribution.hours || ''}`,
    budget: `${contribution.budget || ''}`,
    other: contribution.other || '',
    name: contribution.user.fullname || '',
    userId: contribution.user.id || '',
    isAdmin: !!contribution.user.admin,
    photoSrc: contribution.user.photo || '',
  }
}

export function buildOwnContribution(responseContribution) {
  return {
    userId: `${responseContribution.user.id || ''}`,
    time: `${responseContribution.hours || ''}`,
    budget: `${responseContribution.budget || ''}`,
    other: `${responseContribution.other || ''}`,
  }
}

export function transformContributionsResponse(jsonResponse, state) {
  const ownContribution = jsonResponse.data.find(
    contribution => contribution.user.id === state.data.user.userEmail,
  ) || { user: {} }
  return {
    contributors: jsonResponse.data.map(mapContribution),
    ownContribution: buildOwnContribution(ownContribution),
  }
}

export function transformPostContribution(jsonResponse, state) {
  return {
    contributors: [...state.data.createIdea.contributors, mapContribution(jsonResponse)],
    ownContribution: buildOwnContribution(jsonResponse),
  }
}

export function transformPutContribution(jsonResponse, state) {
  const ownContribution = buildOwnContribution(jsonResponse)
  const contributors = state.data.createIdea.contributors.map(contribution => {
    if (contribution.userId === ownContribution.userId) return mapContribution(jsonResponse)
    return contribution
  })
  return {
    contributors,
    ownContribution,
  }
}

export function transformDeleteContribution(_, state) {
  return {
    contributors: state.data.createIdea.contributors.filter(
      contribution => contribution.userId !== state.data.user.userEmail,
    ),
    ownContribution: buildOwnContribution({ user: {} }),
  }
}

const mapInvestor = investment => ({
  amount: investment.amount,
  email: investment.user.email,
  name: investment.user.fullname,
  photoSrc: investment.user.photo || '',
  created:investment.created   
})

export function transformInvestorsResponse(jsonResponse) {
  const investment = {
    seedInvestors: jsonResponse.seed.map(mapInvestor),
    firstRoundInvestors: jsonResponse.first.map(mapInvestor),
    secondRoundInvestors: jsonResponse.second.map(mapInvestor),
    thirdRoundInvestors: jsonResponse.third.map(mapInvestor),
  }
  const userInvestmentsMap = Object.values(investment).reduce(
    (acc, round) =>
      round.reduce(
        (innerAcc, investor) => ({
          ...innerAcc,
          [investor.email]: (innerAcc[investor.email] || 0) + investor.amount,
        }),
        acc,
      ),
    {},
  )
  return { investment, userInvestmentsMap }
}

export function transformInvestResponse(jsonResponse, state) {
  return {
    balance: `${jsonResponse.user.balance}`,
    maxInvestment: state.data.createIdea.maxInvestment - jsonResponse.amount,
    pledged: `${Number(state.data.createIdea.pledged) + (jsonResponse.amount || 0)}`,
    currentIdeaStatus: jsonResponse.currentIdeaStatus
  }
}

export function transformIdeaTeamResponse(jsonResponse) {
  return {
    pendingInvitationsUsers: getIdeaTeam(jsonResponse.teamInvited),
    pendingInvitationsPilotTeamUsers: getIdeaTeam(jsonResponse.ITeamInvited),
  }
}

function parseStringDate(stringDate) {
  if (!stringDate.includes('UTC')) return `${stringDate} UTC`

  return stringDate
}

export function mapComment(comment, votes = []) {
  return {
    text: comment.text,
    id: `${comment.id || ''}`,
    name: `${comment.author.fullname || ''}`,
    photoSrc: `${comment.author.photo || ''}`,
    balance: `${comment.author.balance || '0'}`,
    email: `${comment.author.email || ''}`,
    replies: `${comment.comments || '0'}`,
    isDeleted: !!comment.deleted,
    votes: `${comment.votes || '0'}`,
    subComments: (comment.subComments || []).map(subComment => mapComment(subComment, votes)),
    timeAgo: formatLocaleDistanceToNow(new Date(parseStringDate(comment.created) || new Date())),
    isVoted: votes.includes(comment.id),
  }
}

export function transformGetCommentsResponse(jsonResponse) {
  const totalComments = jsonResponse.comments.reduce((acc, comment) => {
    return acc + 1 + (comment.deleted ? 0 : (comment.subComments || []).length)
  }, 0)
  return {
    comments: jsonResponse.comments.map(comment => mapComment(comment, jsonResponse.votes)),
    totalComments,
  }
}
