/* eslint-disable react/function-component-definition */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './View.Style';
import investorsImg from '../../imgs/investors.svg';
import pledgedIcon from '../../imgs/pledged.svg';
import investment from '../../imgs/investment.svg';
import i18n from 'support/i18n';



function SummaryCard(props) {
  const { classes,
    userInvestmentsMap,
    totalInvestors,
    pledged,
    goal,
    userEmail,
    submitterName,
    submitterEmail,
    createdDate,
    showInvestors,onClickUserProfile,
    isAnonymous,
  } = props;

  const percentageRaised = Math.round((pledged/goal) * 100)

  const percentageRaisedValue = Number.isNaN(percentageRaised) ? '0' : percentageRaised

  const percentMoreThanHunred = !(percentageRaisedValue > 100) ? percentageRaisedValue : '100'

  // console.log('percentMoreThanhunred..',percentMoreThanHunred )

  let ownInvestment = 0
  if (userEmail in userInvestmentsMap){
    ownInvestment = userInvestmentsMap[userEmail]
  }

  const getDayStr = day => {
    const chars = ['th', 'st', 'nd', 'rd']
    if (( day > 3 && day < 21) || (day % 10 > 3)) return `${day}${chars[0]} `
    // else 
    return `${day}${chars[day%10]} `
  }

  const formatDate = date => {
    const day=getDayStr(date.toLocaleDateString("en-US", {day: "numeric"}))
    const month = date.toLocaleDateString("en-US", {month: "long"})
    const year = date.toLocaleDateString("en-US", {year: 'numeric'})
    return(`${day} ${month} ${year}`)
  }

  return (
    <div className={classes.container}>
        <span className={classes.titles}> 
        <div className={classes.detailsContainer}
          style={{borderRight: '1px solid #EBEBEB'}}>
          <div className={classes.header}>Investors</div>
          <div className={classes.detailsContainer}>
            <img className={classes.icon} src={investorsImg} alt="investors img"/>
            <div className={classes.big_number}>{totalInvestors}</div>
            <button onClick={showInvestors} type='button' className='btns'
              style={{textDecoration: 'underline', cursor: 'pointer', color:'#6D45FF', fontSize: '14px'}}>
              Investors Details
            </button>
          </div>
        </div>
        <div className={classes.detailsContainer}
          style={{borderRight: '1px solid #EBEBEB'}}>
          <div className={classes.header}>Pledged</div>
          <div className={classes.detailsContainer}>
            <img className={classes.icon} src={pledgedIcon} alt="investors img"/>
            <div className={classes.big_number}>{pledged}</div>
            <div style={{fontFamily: 'VodafoneRg-Bold', fontSize: '14px', color: '#646464'}}>
              out of {goal}
            </div>
          </div>
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.header}>My investment</div>
          <img className={classes.icon} src={investment} alt="investors img"/>
          <div className={classes.big_number}>{ownInvestment}</div>
        </div>
        </span>
        <div style={{color: '#333333', fontSize: '16px', marginLeft: '5%', marginBottom: '5px'}}>
          <span style={{fontFamily: 'VodafoneRg-Bold'}}>{`${percentageRaisedValue}% `}</span>Pledged
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className={classes.progressBar}>
            <div style={{
              backgroundColor: '#6D45FF',
              width: `${percentMoreThanHunred}%`,
              height: '100%',
            }} />
          </div>
        </div>
        {isAnonymous && (
          <div className={classes.submittedBy}>
            {i18n.t('SUBMITTED_BY') }  &nbsp; 
          <span className={classes.bold}>   {i18n.t('ANONYMOUS')}
          </span>  &nbsp; on &nbsp; 
          <span className={classes.bold}>{createdDate && formatDate(createdDate)}</span>
        </div>
        )}
        {
          !isAnonymous && (
            <div className={classes.submittedBy}>
            {i18n.t('SUBMITTED_BY') } &nbsp; 

            <span className={classes.bold}> 
              <button type='button' className={classes.btnBlank} onClick={()=>onClickUserProfile(submitterEmail)}> {submitterName} </button>
            </span>  &nbsp; on &nbsp; 
            <span className={classes.bold}>{createdDate && formatDate(createdDate)}</span>
          </div>
          )
        }
       

    </div>
  );
}


export default withStyles(styles)(SummaryCard);
