import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { markNotificationsRead, notificationMeService } from 'services/notifications'
import { showPopupDialog,
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,showPopupInfoSnackbar } from 'support/popup_dialogs'
import CopyrightDialog from 'support/navigation/navigators/logged_in_navigator/components/copyright_dialog/View'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import NewPrivateIdea from 'components/nxt_modal_new_private_idea'
import Navigation from 'support/navigation'
import AnalyticsService from 'support/analytics'
import styles from './View.Style'
import View from './View'
import { setLoggedOut } from 'actions/sync/auth'
import i18n from 'support/i18n'
import { afterLoginWhiteListDomainService } from 'services/domain'
import { msalApp } from 'pagesNewUi/azure_sso/auth-utils'
import ConfirmSubmitContainer from './components/ConfirmSubmitContainer'

const mapStateToProps = state => ({
  domain: state.data.domain.currentDomain,
  isLoading: state.data.domain.isLoading,
  appVersion: process.env.REACT_APP_VERSION,
  copyrightYear: process.env.REACT_APP_COPYRIGHT,
  logoUrl: state.data.domain.domainConfiguration.logo,
  customFooterText: state.data.domain.domainConfiguration.customFooterText,
  newIdeas: state.data.domain.domain.newIdeas,
  notifications: state.data.user.notifications,
  showAdmin: state.data.user.admin,
  userBalance: state.data.user.balance,
  userName: state.data.user.userName,
  userPhoto: state.data.user.userPhoto,
  userLoginEmail: state.data.user.userEmail,
  new_ui: state.data.domain.new_ui,
  totalInvestments: state.data.domain.domain.totalInvestments,
  currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  domains: state.data.user.domains,
  isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
  query: state.explore.search_query,
  commonUserData : state.data.user,
    localAdmin: state.data.user.localAdmin,
    localInnovator: state.data.user.localInnovation,
    isOwner: state.data.createIdea.owner === state.data.user.userEmail,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    canProposeChallenge:
      !state.data.user.admin &&
      !state.data.user.isInnovator &&
      !state.data.user.localAdmin &&
      state.data.domain.domainConfiguration.challengeProposal,
})

const mapDispatchToProps = dispatch => {
  return {
    onLogoClick: () => {
      Navigation.navigateToExplore()
    },
    changeUI: () => dispatch({ type: 'CHANGE_UI' }),
    onCopyrightClick: year =>
      showPopupDialog(
        <CopyrightDialog year={year} onAcceptClick={() => dispatch(hideDialog())} />,
        dispatch,
      ),
    navigateToDomain: domainId => {
      Navigation.navigateToDomain(domainId)
    },

    whiteListDomainService: (userEmail,domainName) => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          // const msg1 = <p>You will be automatically logged out, and the submission was successful.<br /> After you log in again, you will be able to select your chosen instance.</p>
          const msg1 = <p>Your domain access request submission was successful.</p>
          showPopupSuccessSnackbar(msg1, dispatch)
          setTimeout(() => {
            dispatch(setLoggedOut())
            if (msalApp.getAccount()) msalApp.logout();
          }, 4000)
        }
        const onFailure = ({ data }) => {
          const messageTag =
            data && data.error && data.error.error
              ? `${data.error.desc}`
              : 'BACKEND_ERRORS.UNEXPECTED'
          showPopupErrorSnackbar(messageTag, dispatch)
        }
        // dispatch(reportIdea(id, onSuccess))
        dispatch(afterLoginWhiteListDomainService(userEmail,domainName,onSuccess,onFailure))
        dispatch(hideDialog())
      }

      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title='Access Request'
          description="you will be automatically logged out, and your submission was successful. After logging in again, you will be able to select your chosen instance."
          // title={i18n.t('EXPLORE_IDEA.REPORT_IDEA')}
          // description={i18n.t('EXPLORE_IDEA.REPORT_IDEA_TEXT')}
        />,
        dispatch,
      )
    },


    // whiteListDomainService:(userEmail,domainName )=>{
    //     // showPopupInfoSnackbar('please wait...', dispatch)
    //     // const msg1 = <p>You will be automatically logged out and after you<br />login you will be able to select your chosen instance</p>
    //     const msg1 = <p>You will be automatically logged out, and the submission was successful.<br /> After you log in again, you will be able to select your chosen instance.</p>
    //     // showPopupSuccessSnackbar(msg1, dispatch)
    //     const onSuccess = () => {
    //         showPopupSuccessSnackbar(msg1, dispatch)
    //        setTimeout(()=>{
    //         dispatch(setLoggedOut())
    //         if (msalApp.getAccount()) msalApp.logout(); 
    //        },4000)
           
    //     }
    //     const onFailure = ({ data }) => {
    //       const messageTag =
    //         // commented for not showing relevent error messages
    //         // data && data.error && data.error.error
    //         //   ? `BACKEND_ERRORS.${data.error.error}`
    //         //   : 'BACKEND_ERRORS.UNEXPECTED'
    //         data && data.error && data.error.error
    //           ? `${data.error.desc}`
    //           : 'BACKEND_ERRORS.UNEXPECTED'
    //           showPopupErrorSnackbar(messageTag, dispatch)
    //     }

    //   dispatch(afterLoginWhiteListDomainService(userEmail,domainName,onSuccess,onFailure))
    // },

    navigateToSearchPage: () => {
      Navigation.navigateToSearchPage()
    },
    submitYourIdea: (isPrivate = false) => {
      if (isPrivate) {
        showPopupDialog(<NewPrivateIdea />, dispatch)
      } else {
        dispatch(Navigation.navigateToNewIdea())
      }
      AnalyticsService.trackEvent({
        category: 'create-idea',
        action: 'click',
        label: 'home footer button',
      })
    },
    navigateToExplore: () => {
      Navigation.navigateToExplore()
    },
    navigateToAllIdeas: () => {
      Navigation.navigateToAllIdeas()
    },
    navigateToProposeChallenge: () => {
      Navigation.navigateToProposeChallenge()
    },
    navigateToNewChallenge:()=>{
      Navigation.navigateToChallengeNew()
    },
    navigateToMyIdeas: () => {
      Navigation.navigateToMyIdeas()
    },
    navigateToMyChallenge: () => {
      Navigation.navigateToMyChallenge()
    },
    
    navigateToInvestments: () => {
      Navigation.navigateToInvestments()
    },
    navigateToMyInvestedIdeas: () => {
      Navigation.navigateToMyInvestedIdeas()
    },
    navigateToAboutus: () => {
      Navigation.navigateToAboutus()
    },
    navigateToNovaResearch: () => {
      Navigation.navigateToNovaResearch()
    },
    navigateToNovaContact : ()=>{
      Navigation.navigateToNovaContact()
    },
    navigateToChallenges: () => {
      Navigation.navigateToChallenges()
    },
    navigateToCaseStudy: () => {
      Navigation.navigateToCaseStudy()
    },
    markNotificationsRead: () => {
      const onSuccess = () => {
        dispatch(notificationMeService())
      }
      dispatch(markNotificationsRead(onSuccess))
    },
    applySearch: query => dispatch({type: 'SET_SEARCH', data: query})
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(View))
