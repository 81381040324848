import { withStyles } from '@material-ui/core/styles'
import styles from './View.Style'
import * as PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import {getStyledStatus} from 'services/GetStatusStyle'


const Button = props => {
    const {
        classes,
        text,
        onClick,
        styled,
        disabled
    } = props
    return(
        <button type='button' disabled={disabled} className={styled ? classes.button2 : classes.button1} onClick={onClick}>{text}</button>
    )
}

Button.defaultProps = {
    text: 'Click Here',
    styled: true
}



export default withStyles(styles)(Button)