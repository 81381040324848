/* eslint-disable  prefer-template */
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { Link,NavLink } from 'react-router-dom'


const ButtonEleDropdown = ({ option, dataRequestAccess, onHandleInstanceValue, whiteListDomainLoading }) => {
    const newArray = [...option, ...dataRequestAccess]
    const uniqueArrayOfRequestAccessList = Array.from(new Set(newArray.map(entity => entity.id))).map(name => newArray.find(entity => entity.id === name));
    // console.log('======----', uniqueArrayOfRequestAccessList)
    const data = uniqueArrayOfRequestAccessList.map((domain) => {
        const domainNameMatchId = dataRequestAccess.find(element => {
            return element?.id === domain?.id
        });
        return (
            <button
                key={domain.id}
                type='button'
                disabled={domainNameMatchId || whiteListDomainLoading}
                className='btn-transparent'
                onClick={onHandleInstanceValue}
                value={domain.id}>
                {/* <span><img src={VoisIcon} alt='voisincon' /></span>  */}
                {domain.name}
            </button>
        )
    })
    return (
        data
    )
}
   


const DropdownEle = ({submenu,closeSidebarMenu,domains,onHandleInstanceValue,whiteListDomainLoading})=>{    
    const [isOpen, setIsOpen] = useState(false);
    //close outside click menu
    const ref = useRef()
    useEffect(() => {
        const handler = (event) => {
            if (isOpen && ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [isOpen]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        if(isOpen){
            setIsOpen(false);
        }
    };

    if(whiteListDomainLoading && submenu.buttonEle === true){
        return  <p style={{fontSize:'14px'}}>Please wait... </p>
    }
    return(
        <div role="button" tabIndex="0" className="menu-dropdown" onClick={closeDropdown} ref={ref}>
            <button type='button' className="dropdown-toggle" onClick={handleToggle} >
                {submenu.title}
                <span className={clsx('icon-down')} />
            </button>
            {isOpen && (
                <ul className='dropdown-menu dropdown'>
                    {
                        submenu.sub.map((option) => (
                          <li key={option.title}  >
                              {  
                               submenu.buttonEle === true ? null :
                                option && option.adminRole !== false &&  <NavLink to={option.path} onClick={closeSidebarMenu} className="menuItem">{option.title}</NavLink>
                              }
                            </li>
                        ))
                    }
                    {
                        ( submenu.buttonEle === true )&&
                            <ButtonEleDropdown 
                                dataRequestAccess={domains}  
                                option={submenu.buttonEle === true && submenu.sub} 
                                onHandleInstanceValue={onHandleInstanceValue}
                                whiteListDomainLoading={whiteListDomainLoading}
                            />
                    }
                </ul>
            )}
        </div>
    )
}


const MenuItem = ({navbarItem,onHandleInstanceValue,whiteListDomainLoading,domains,closeSidebarMenu}) => {
  return (
    <>
        {
              navbarItem.map((item) => (
                  item.sub ?
                      <DropdownEle key={item.title} 
                        submenu={item} 
                        domains={domains} 
                        onHandleInstanceValue={onHandleInstanceValue}
                        whiteListDomainLoading={whiteListDomainLoading}    
                    />
                      : <NavLink key={item.title} className={isActive =>
                          "menuItem" + (!isActive ? " unselected " : "")
                      } to={item.path} onClick={closeSidebarMenu} >{item.title}</NavLink>
              ))
        }
    
    </>
  )
}

export default MenuItem